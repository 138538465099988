import './style.scss'
import {Button, Input, notification} from 'antd';
import React, {useEffect} from 'react';
import {api_address} from "../config/config";
import type {NotificationPlacement} from 'antd/es/notification/interface';
import {useNavigate} from "react-router";
import {fetchLogin} from "../service/api";

export function LoginPage() {
    const [myKey, setMyKey] = React.useState('') //卡密
    const [api, contextHolder] = notification.useNotification(); //弹窗
    const navigate = useNavigate();

    //弹窗
    const openNotification = (placement: NotificationPlacement, title, msg) => {
        api.info({
            message: title,
            description: msg,
            placement,
        });
    };
    useEffect(() => {
        //判断是否已经登录
        if (localStorage.getItem('token')) {
            navigate('/index');
        }
    }, [])
    async function login() {
        let data = {
            kami: myKey,
        }
        try {
            let res = await fetchLogin(data)
            if (res.status === 200) {
                openNotification('top', '登录成功', '欢迎您')
                setTimeout(() => {
                    //设置本地cookie
                    document.cookie = `token=${res.token}`
                    // 将cookie 存到lockalStorage
                    localStorage.setItem('token', res.token)
                    localStorage.setItem('endTime', res.endTime)
                    localStorage.setItem('isFirstLogin', true);
                    localStorage.setItem('kami', res.kami);
                    //跳转到首页
                    navigate('/index');
                }, 1000)
            }
        }catch (e) {
            openNotification('top', '登录失败', e.message)
        }


    }

    //弹窗

    //处理登录
    function handleLogin() {
        if (myKey === '') {
            openNotification('top', '出错了', '请输入卡密')
        } else {
            login()
        }
    }

    function handleKeyDown(e) {
        if (e.keyCode === 13) {
            if (myKey === '') {
                openNotification('top', '出错了', '请输入卡密')
            } else {
                login()
            }
        }
    }

    return (
        <div className="login">
            {contextHolder}
            <div className="contner">
                <div className="head_cont">
                    {/*<h1>登录</h1>*/}
                </div>
                <div className="center_cont">
                    <div className="login_cont">
                        <Input value={myKey} onKeyDown={handleKeyDown} onChange={(e) => {
                            setMyKey(e.target.value)
                        }} placeholder="请输入卡密" allowClear/>
                        <Button type="primary" size="large" onClick={handleLogin}>登录</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}