import './style.scss'
import {DoubleRightOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";


export default function MyProofread(props) {
    /*
    * correction 纠正后的文本
    * string_to_correct 当前的文本，错误的
    * group_type 分组类型
    * interpretation 释义
    * error_desc_zh 错误提示描述
    * error_desc_sm_en 错误提示，简介
    * */
    const {correction, string_to_correct, error_desc_zh, error_desc_sm_en, group_type, interpretation} = props
    const [top_cont, setTopCont] = useState(<div/>)
    //判断错误分组类型

    useEffect(() => {
        handle_group_type()
    }, [group_type])
    const handle_group_type = () => {
        console.log('group_type', group_type)
        // 标点错误
        if (group_type === 'punctuation_mistakes') {
            setTopCont(
                <div className="cont-top">
                    <div className="punctuation_cont">
                        {string_to_correct}
                    </div>
                </div>
            )
        }
        // 语法错误
        if (group_type === 'advanced_syntax_error') {
            setTopCont(
                <div className="cont-top">
                    <div className="string_text">
                        {string_to_correct}
                    </div>
                    <div className="pro-ico"> ></div>
                    <div className='correct-text'>{correction}</div>

                </div>
            )
        }
        if (group_type === 'typos') {
            setTopCont(
                <div className="cont-top">
                    <div className="string_text">
                        {string_to_correct.isArray ?
                            string_to_correct.map((item, index) => {
                                return <span key={index}>{item}</span>
                            })
                            : string_to_correct}

                    </div>
                    <div className="pro-ico"> ></div>
                    <div className='correct-text'>{correction}</div>
                </div>
            )
        }
    }

    return (
        <div className="my-proo-cont-main">
            {/*主要信息*/}
            {top_cont}
            {/*校对提示文本*/}
            <div className="cont-msg">
                <div>
                    <span>{error_desc_zh}</span>
                    {
                        interpretation?
                            <div className='correct-text'>
                                <span style={{fontWeight: '500'}}>【释义】</span>
                                {interpretation}
                            </div> : ''
                    }
                </div>
            </div>
        </div>
    )
}