import {api_address} from "../config/config";
import React from 'react'


async function commFetchData(url, data) {
    try {
        const response = await fetch(`${api_address}/${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        const responseData = await response.json();
        // if (responseData.hasOwnProperty('status')) {
        //     if (responseData.status === 401){
        //         throw new Error('请求出错，请稍后再试')
        //     } else if (responseData.status === 400 || responseData.status === 500) {
        //         throw new Error('字数超出限制，请修改后再试');
        //     } else if (responseData.status === 300 || responseData.status === 403) {
        //         localStorage.removeItem('token');
        //         alert('账号失效，卡密过期。即将跳转到登录页面')
        //         window.location.href = '/'
        //     } else if (responseData.status === 402) {
        //         throw new Error('请求出错，请稍后再试');
        //     }
        // }

        if (responseData.content === null) {
            throw new Error(responseData.msg);
        }
        if (responseData.hasOwnProperty('status')) {
            console.log("状态码为" + responseData.status)
            if (responseData.status === 401 || responseData.status === 500) {
                throw new Error(responseData.msg)
            } else if (responseData.status === 400) {
                responseData.content = null;
                throw new Error(responseData.msg);
            } else if (responseData.status === 300 || responseData.status === 403 || responseData.status === 402) {
                localStorage.removeItem('token');
                alert(`${responseData.msg}，即将跳转到登录页面`)
                window.location.href = '/'
                return
            }
        }

        return responseData;

    } catch (error) {
        throw error;
    }
}


export {
    commFetchData
}