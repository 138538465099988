import './myList.scss'
import {useEffect, useState} from "react";
import clipboardCopy from "clipboard-copy";

import React from 'react';
import {CheckOutlined, CopyOutlined} from '@ant-design/icons';
import {message} from 'antd';


export function MyList(props) {
    const {cont, from, title, href, doi} = props;
    const [content, setContent] = useState(cont);
    const [messageApi, contextHolder] = message.useMessage();

    const success = (msg) => {
        messageApi.open({
            type: 'success',
            content: msg,
        });
    };

    useEffect(() => {
        setContent(cont);
    }, [cont]);
    const handleCopy = () => {
        const text = Array.isArray(content) ? content.join('\n') : content;

        const textArea = document.createElement('textarea');
        textArea.value = text;

        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);

        success('复制成功');
    };


    //应用
    function handleApply() {
        props.onClick(content);
        success('应用成功');
    }

    return (
        <div className="cont-main">
            {contextHolder}

            <div className="cont-text">
                {Array.isArray(cont) ? cont.map((line, index) => (
                    <React.Fragment key={index}>
                        <p>{line}</p>
                    </React.Fragment>
                )) : cont}
                <div className={title ? 'cont-foot-active' : 'cont-foot-dis-active'}>
                    <p>
                        <span style={{color: '#999'}}> 来源 : </span>
                        <a style={{margin: '0 10px'}} href={href}>{from} - {title}</a>
                    </p>
                    {doi ? <p style={{color: '#999', fontSize: '0.9em'}}>
                        <span>doi :{doi}</span>
                    </p> : ''}
                </div>
            </div>
            <div className="btns">
                <button className="apply" onClick={handleApply}>
                    <CheckOutlined/>
                    应用
                </button>
                <button className="copy" onClick={handleCopy}>
                    <CopyOutlined/>
                    复制
                </button>
            </div>
        </div>
    )
}