import '../style.scss'
import '../myStyle.scss'
import CharacterCount from '@tiptap/extension-character-count'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import {EditorContent, useEditor} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, {useEffect, useRef, useState} from 'react'
import MenuBar from '../MenuBar'
import {MyList} from '../../contList/MyList'
import {Drawer, notification, Skeleton, Tour, TourProps} from "antd";
import type {NotificationPlacement} from "antd/es/notification/interface";
import {commFetchData} from "../../util/util";
import {
    CheckOutlined,
    CloseCircleOutlined, CloseSquareOutlined,
    DoubleRightOutlined,
    FormOutlined,
    ReloadOutlined,
    SwapOutlined
} from "@ant-design/icons";
import {Placeholder} from "@tiptap/extension-placeholder";
import {useNavigate} from "react-router";
import MyProofread from "../../proofreadComponent/MyProofread";

export function Hight() {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const [open, setOpen] = useState(false);
    const [changeData, setChangeData] = useState({})
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification(); //弹窗
    const navigate = useNavigate();
    const [myItem, setMyItem] = useState(<h1
        className="right_msg">在左侧发挥您的构思吧，点击按钮后，我会帮助您建议您的文章</h1>) //列表
    const [visible, setVisible] = useState(false); //抽屉
    const [expandVisible, setExpandVisible] = useState(false); //扩展抽屉
    const [translateVisible, setTranslateVisible] = useState(false); //翻译抽屉
    const [strongVisible, setStrongVisible] = useState(false); //强力抽屉
    const [academicVisible, setAcademicVisible] = useState(false); //强力抽屉
    const [proofreadVisible, setProofreadVisible] = useState(false); //校对抽屉
    const [isChinese, setIsChinese] = useState(true); //是否中文
    const [kamiEndTime, setKamiEndTime] = useState(''); //卡密结束时间
    const [btnisDisabled, setBtnisDisabled] = useState(false); //按钮是否可用
    // 翻译文本
    const [translateItem, setTranslateItem] = useState(<p></p>); //翻译文本
    // 校对文本
    const [proofreadItem, setProofreadItem] = useState(<p/>); //校对文本
    const [punctuationItem, setPunctuationItem] = useState(<p/>); //标点文本
    const [grammarItem, setGrammarItem] = useState(<p/>); //语法文本


    //渲染校对文本
    const [proofreadTextItem, setProofreadTextItem] = useState(<p>点击校对按钮开始对您的文章进行校对吧</p>); //校对文本

    const steps: TourProps['steps'] = [
        {
            title: '编辑您的论文',
            description: '这里是编辑区，您可以尽情发挥您的创意',
            target: () => ref1.current,
            placement: 'rightBottom',
        },
        {
            title: '这里对您的文本进行改写',
            description: '我们提供了多种改写技术，将使用最强大的AI技术为您提供修改建议',
            target: () => ref2.current,
            placement: 'rightTop',
        },
        {
            title: 'welecome to use',
            description: '祝您使用愉快',
            target: () => ref3.current,
            placement: 'leftTop',
        },
    ];

    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                history: true,
            }),
            Highlight,
            TaskList,
            Placeholder,
            TaskItem,
            CharacterCount.configure({
                limit: 60000,
            }),
            Placeholder.configure({
                placeholder: '请输入您的文章',
            }),
        ],
    })
    // 定义text 为editor的内容 如果没有内容则为空
    const text = editor ? editor.getText() : ''
    const linesArray = text.split('\n').filter(function (line) {
        return line.trim() !== ''; // 过滤空行
    });
    //弹窗
    const openNotification = (placement: NotificationPlacement, title, msg) => {
        api.info({
            message: title,
            description: msg,
            placement,
        });
    };

    //页面加载调用
    useEffect(() => {
        init()
    }, [])


    //获取选中的内容
    const getSelection = () => {
        if (editor) {
            const {from, to} = editor.state.selection;
            const text = editor.getText();
            const selectedText = text.slice(from - 1, to - 1);
            if (selectedText === '\n' && from === to - 1) {
                return ''; // 如果选中的是空行，则返回空字符串
            }
            return selectedText.replace(/\r?\n/g, '');
        }

        return '';
    };

    //设置选中的内容
    const setSelection = (value) => {
        if (editor) {
            const {from, to} = editor.state.selection
            editor.commands.insertContentAt({from: from, to: to}, value)
        }
    }

    //加载本地文本
    function loadLocalText() {
        let localText = localStorage.getItem('text')
        if (localText === null) {
            localText = ''
        }
        // 获取当前文本内容
        let cont = editor.getText()
        if (cont.length === 0) {
            editor.commands.setContent(localText.toString())
        }
    }

    const handleUpdate = () => {
        const newContent = editor.getHTML()
        localStorage.removeItem('text')
        localStorage.setItem('text', newContent.toString());
    };

    // 强力改写选中文本
    const changeText = async () => {
        try {
            debounce()
            if (btnisDisabled) {
                openNotification('top', '出错了', '点击太快了，稍后再试吧')
                return
            }
            const selection = getSelection() // 获取选中的文本内容
            if (selection) {
                setChangeData({})
                setLoading(true); // 显示骨架屏
                // fetchData(selection)
                let data = {
                    'text': selection,
                    'token': localStorage.getItem('token')
                }
                let rep = await commFetchData('select_strong_v3', data);
                setLoading(false); // 隐藏骨架屏
                setMyItem(rep.results.map((item, index) => {
                    return <MyList cont={item} key={index} onClick={item => doSomething(item)}/>;
                }))
            } else {
                openNotification('top', '抱歉', '您未选中任何文本')
            }
        } catch (e) {
            openNotification('top', '出错了', e.message,)
            console.log(e)
        }

    }

    // 强力改写全部文本
    const changeTextAll = () => {
        try {
            debounce()
            if (btnisDisabled) {
                openNotification('top', '出错了', '点击太快了，稍后再试吧')
                return
            }
            let allText = editor.getText()
            let linesArray = allText.split('\n').filter(function (line) {
                return line.trim() !== ''; // 过滤空行
            });

            setMyItem(<div></div>)
            setChangeData({})
            setLoading(true); // 显示骨架屏

            if (linesArray.length === 0) {
                openNotification('top', '抱歉', '请输入文本')
                return
            }
            //全文改写
            let cont = []
            // 遍历 linesArray
            linesArray.map(async (item, index) => {
                //段落
                let data = {
                    "text": item,
                    // "is_title": false,
                    // "previous_text": "",
                    // "next_text": "",
                    "kami": localStorage.getItem('kami'),
                    "token": localStorage.getItem('token')
                }
                try {
                    let rep = await commFetchData('strong_v3', data);
                    cont[index] = rep.res.content
                    cont.map((item, index) => {
                        if (item === undefined) {
                            cont[index] = ''
                        }
                        setMyItem(
                            <MyList cont={cont} key={index} onClick={item => doSomething(item)}/>
                        )
                    })
                } catch (e) {
                    setChangeData({})
                    setLoading(false)
                    openNotification('top', '出错了', e.message)
                }

                setLoading(false)
            })
        } catch (e) {
            setChangeData({})
            setLoading(false)
            openNotification('top', '出错了', e.message)
        }
    }

    //初始化页面调用
    function init() {
        //判断用户是否第一次登录
        let isFirst = localStorage.getItem('isFirstLogin')
        let endTime = localStorage.getItem('endTime')
        if (endTime) {
            setKamiEndTime(endTime)
        }
        if (isFirst === 'true') {
            setOpen(true)
            // 设置本地存储标记为已登录
            localStorage.setItem('isFirstLogin', false);
        }

        let isLogin = localStorage.getItem('token')
        if (!isLogin) {
            alert("请先登录")
            navigate('/');
            //删除所有 dom
            setOpen(false)
        }
    }

    function doSomething(value) {
        // 判断value是否为数组
        if (Array.isArray(value)) {
            // 如果是数组，就把数组转换成字符串
            // 将value 使用 p标签包裹起来，然后转换成字符串，去除逗号，去除第一个p标签
            value = value.map(item => `<p>${item}</p>`).join('').replace(/,/g, '').replace('<p>', '').replace('</p>', '')
        }

        setSelection(value)
        //使滚动条在光标处
        editor.commands.scrollIntoView()
    }

    //普通改写全部文本
    const changeText_Default = () => {
        try {
            debounce()
            if (btnisDisabled) {
                openNotification('top', '出错了', '点击太快了，稍后再试吧')
                return
            }
            let allText = editor.getText()
            let linesArray = allText.split('\n').filter(function (line) {
                return line.trim() !== ''; // 过滤空行
            });

            setMyItem(<div></div>)
            setChangeData({})
            setLoading(true); // 显示骨架屏

            if (linesArray.length === 0) {
                openNotification('top', '抱歉', '请输入文本')
                return
            }
            //全文改写
            let cont = []
            // 遍历 linesArray
            linesArray.map(async (item, index) => {
                //段落
                let data = {
                    "text": item,
                    "token": localStorage.getItem('token'),
                    "kami": localStorage.getItem('kami')
                }
                try {
                    let rep = await commFetchData('aiRemove', data);
                    cont[index] = rep.res.content
                    cont.map((item, index) => {
                        if (item === undefined) {
                            cont[index] = ''
                        }
                        setMyItem(
                            <MyList cont={cont} key={index} onClick={item => doSomething(item)}/>
                        )
                    })
                } catch (e) {
                    setChangeData({})
                    setLoading(false)
                    openNotification('top', '出错了', e.message)
                }

                setLoading(false)
            })
        } catch (e) {
            setChangeData({})
            setLoading(false)
            openNotification('top', '出错了', e.message)
        }
    }

    //基础改写选中文本
    const changeTestForSelectStrong = async () => {
        try {
            debounce()
            if (btnisDisabled) {
                openNotification('top', '出错了', '点击太快了，稍后再试吧')
                return
            }
            const selection = getSelection()
            let allText = editor.getText()
            let linesArray = allText.split('\n').filter(function (line) {
                return line.trim() !== ''; // 过滤空行
            });
            setMyItem(<div></div>)
            setChangeData({})
            setLoading(true); // 显示骨架屏
            if (selection && selection.toString().length > 1) {
                //这里是选中文本
                let data = {
                    "text": selection.toString(),
                    "token": localStorage.getItem('token'),
                    "kami": localStorage.getItem('kami')
                }
                let rep = await commFetchData('select_airemove', data);
                setLoading(false)
                setMyItem(rep.results.map((item, index) => {
                    return <MyList cont={item} key={index} onClick={item => doSomething(item)}/>
                }))
            } else {
                openNotification('top', '出错了', '未选中文本，或选中文本过少')
                setChangeData({})
                setLoading(false); // 隐藏骨架屏
            }
        } catch (e) {
            setChangeData({})
            setLoading(false); // 隐藏骨架屏
            openNotification('top', '出错了', e.message)

        }
    }
    //显示改写
    const showDrawer = () => {
        setMyItem(<h1
            className="right_msg">在左侧发挥您的构思吧，建议输入10字以上，点击按钮后.我会帮助您建议您的文章</h1>)
        if (visible) {
            setVisible(false)
        } else {
            setVisible(true)
            setExpandVisible(false)
            setTranslateVisible(false)
            setAcademicVisible(false)
            setStrongVisible(false)
            setProofreadVisible(false)
        }
    };
    //显示扩展
    const showExpand = () => {
        setMyItem(<h1
            className="right_msg">在左侧发挥您的构思吧，点击按钮后.我会帮助您建议您的文章</h1>)
        if (expandVisible) {
            setExpandVisible(false)
        } else {
            setExpandVisible(true)
            setTranslateVisible(false)
            setVisible(false)
            setAcademicVisible(false)
            setStrongVisible(false)
            setProofreadVisible(false)
        }
    };
    //显示翻译
    const showTranslate = () => {
        if (translateVisible) {
            setTranslateVisible(false)
        } else {
            setTranslateVisible(true)
            setExpandVisible(false)
            setVisible(false)
            setStrongVisible(false)
            setAcademicVisible(false)
            setProofreadVisible(false)
        }
    };
    //显示学术改写
    const showAcademic = () => {
        setMyItem(<h1
            className="right_msg">在左侧发挥您的构思吧，建议输入10字以上，点击按钮后.我会帮助您建议您的文章</h1>)
        if (academicVisible) {
            setAcademicVisible(false)
        } else {
            setAcademicVisible(true)
            setExpandVisible(false)
            setVisible(false)
            setStrongVisible(false)
            setProofreadVisible(false)
        }
    };
    //显示基础改写 （原来是显示强力改写）
    const showStrong = () => {
        setMyItem(<h1
            className="right_msg">在左侧发挥您的构思吧，建议输入10字以上，点击按钮后.我会帮助您建议您的文章</h1>)
        if (strongVisible) {
            setStrongVisible(false)
        } else {
            setTranslateVisible(false)
            setExpandVisible(false)
            setVisible(false)
            setStrongVisible(true)
            setAcademicVisible(false)
            setProofreadVisible(false)
        }
    };
    //显示校对
    const showProofreadVisible = () => {
        setMyItem(<h1
            className="right_msg">在左侧发挥您的构思吧，点击按钮后.我会帮助您建议您的文章</h1>)
        if (proofreadVisible) {
            setProofreadVisible(false)
        } else {
            setTranslateVisible(false)
            setExpandVisible(false)
            setVisible(false)
            setStrongVisible(false)
            setAcademicVisible(false)
            setProofreadVisible(true)
        }
    };

    const onClose = () => {
        // setVisible(false);
        if (expandVisible) {
            setExpandVisible(false)
        } else if (translateVisible) {
            setTranslateVisible(false)
        } else if (visible) {
            setVisible(false)
        } else if (academicVisible) {
            setAcademicVisible(false)
        } else if (strongVisible) {
            setStrongVisible(false)
        } else if (proofreadVisible) {
            setProofreadVisible(false)
        }
    };

    //扩展文本
    const expandText = async () => {
        try {
            debounce()
            if (btnisDisabled) {
                openNotification('top', '出错了', '点击太快了，稍后再试吧')
                return
            }
            // showExpand()
            //选中
            const selection = getSelection()
            if (selection) {
                setLoading(true)
                let data = {
                    "text": selection,
                    // "afterText": "",
                    // "type": "common",
                    "token": localStorage.getItem('token')
                }
                let rep = await commFetchData('prediction', data)

                setLoading(false)
                setMyItem(rep.result.map((item, index) => (
                    <MyList key={index} cont={item} className="expand_item"
                            onClick={() => doSomething(item)}>{item}</MyList>
                )))

            } else {
                openNotification('top', '无法扩展', '未选中任何文本')
            }
        } catch (e) {
            openNotification('top', '出错了', e.message)
            setLoading(false)
        }


    }

    //改写文本
    const changeText2 = () => {
        setChangeData({})
        showDrawer()
    }

    //翻译文本
    const translateText = () => {
        showTranslate()
    }

    //按钮防抖
    const debounce = () => {
        setBtnisDisabled(true)
        setTimeout(() => {
            setBtnisDisabled(false)
        }, 1000)
    }

    //段落推荐
    const recommendText = async () => {
        try {
            debounce()
            if (btnisDisabled) {
                openNotification('top', '出错了', '点击太快了，稍后再试吧')
                return
            }
            //如果选中
            const selection = getSelection()
            if (selection) {
                setLoading(true)
                let data = {
                    "type": "common",
                    "index": 1,
                    "paragraph": selection,
                    "article": "",
                    "doc_id": 1,
                    "title": "Untitled",
                    "token": localStorage.getItem('token')
                }
                let res = await commFetchData('writing_recommendation_v2', data)
                setLoading(false)
                setMyItem(res.public.sentence.map((item, index) => (
                    <MyList key={index} cont={item.content} doi={item.doi} from={item.from} title={item.title}
                            href={item.href} className="expand_item"
                            onClick={() => doSomething(item.content)}>{item.content}</MyList>
                )))
            } else {
                openNotification('top', '无法扩展', '未选中段落')
            }
        } catch (e) {
            openNotification('top', '出错了', e.message)
        }
    }

    const translateTextFu = async () => {
        try {
            debounce()
            if (btnisDisabled) {
                openNotification('top', '出错了', '点击太快了，稍后再试吧')
                return
            }
            // changeLanguage()
            //如果选中
            const selection = getSelection()
            if (selection) {
                setLoading(true)
                let data = {
                    "text": selection,
                    "en": isChinese,
                    "token": localStorage.getItem('token')
                }
                let rep = await commFetchData('translate', data)
                setLoading(false)
                setTranslateItem(
                    <p className="translate_item">{rep.content}</p>
                )


            } else {
                console.log("进入到了全文翻译。。")
                if (linesArray.length === 0) {
                    openNotification('top', '无法翻译', '没有内容')
                    return
                }
                setLoading(true)
                // setChangeData({})
                setTranslateItem([])
                let cont = []
                linesArray.map(async (item, index) => {
                    let data = {
                        "text": item,
                        "en": isChinese,
                        "token": localStorage.getItem('token')
                    }
                    let result = {};
                    try {
                        result = await commFetchData('translate', data);
                    } catch (e) {
                        openNotification('top', '出错了', e.message)
                    }
                    cont[index] = result.content
                    setLoading(false)
                    setTranslateItem(cont.map((item, index) => (
                        <p className="translate_item" key={index}>{item}</p>
                    )));
                })
            }
        } catch (e) {
            // console.log("630出错了")
            openNotification('top', '出错了', e.message)
        }
    }

    //中英切换
    const changeLanguage = () => {
        if (isChinese) {
            setIsChinese(false)
        } else {
            setIsChinese(true)
        }
    }

    //学术改写
    const academicRewrite = async () => {
        try {
            debounce()
            if (btnisDisabled) {
                openNotification('top', '出错了', '点击太快了，稍后再试吧')
                return
            }
            //如果选中
            const selection = getSelection()
            if (selection) {
                setLoading(true)
                let data = {
                    "text": selection.toString(),
                    "token": localStorage.getItem('token'),
                    "kami": localStorage.getItem('kami')
                }
                let rep = await commFetchData('select_strong_v4', data)
                setMyItem(rep.results.map((item, index) => {
                    return <MyList cont={item} key={index} onClick={item => doSomething(item)}/>;
                }))
                setLoading(false)

            } else {
                openNotification('top', '抱歉', '请先选中文本后改写')
            }


        } catch (e) {
            openNotification('top', '出错了', e.message)
        }
    }

    //改写全部学术文本
    const academicRewriteAll = async () => {
        try {
            debounce()
            if (btnisDisabled) {
                openNotification('top', '出错了', '点击太快了，稍后再试吧')
                return
            }

            if (linesArray.length === 0) {
                openNotification('top', '抱歉', '请输入文本')
                return
            }
            let cont = []
            setChangeData({})
            setLoading(true)
            linesArray.map(async (item, index) => {
                let data = {
                    "text": item,
                    "token": localStorage.getItem('token'),
                    "kami": localStorage.getItem('kami')
                }
                let rep = await commFetchData('strong_v4', data)
                cont[index] = rep.res.content
                setMyItem(
                    <MyList cont={cont} onClick={item => doSomething(item)}/>
                )
                setLoading(false)
            })
        } catch (e) {
            openNotification('top', '出错了', e.message)
        }
    }

    //校对文本
    const proofreadText = async () => {
        try {
            debounce()
            if (btnisDisabled) {
                openNotification('top', '出错了', '点击太快了，稍后再试吧')
                return
            }
            // setProofreadTextItem(<h1></h1>)
            setLoading(true)
            let data = {"sentence": text, "ame": 0, "token": localStorage.getItem('token')}
            let rep = await commFetchData('correct', data)

            // console.log(rep)
            let rep_data = rep.corrected_sentences[0].revisions
            let categorizedItems = {
                typos: [],
                advanced_syntax_error: [],
                punctuation_mistakes: []
            };

            rep_data.forEach((item, index) => {
                if (categorizedItems.hasOwnProperty(item.group_type.toString())) {
                    categorizedItems[item.group_type].push(item);
                } else {
                    console.log(item.group_type)
                }
            });
            console.log(rep_data)
            //如果没有错误
            if (rep_data.length === 0) {
                openNotification('top', '', '未发现错误')
            }else{
                openNotification('top', '', '校对完毕 请检查错误')
            }
            setLoading(false)
            setGrammarItem(categorizedItems.advanced_syntax_error.map((item, index) => (
                <MyProofread
                    key={index}
                    correction={item.correction}
                    string_to_correct={item.string_to_correct}
                    error_desc_zh={item.error_desc_zh}
                    error_desc_sm_en={item.error_desc_sm_en}
                    group_type={item.group_type}
                    interpretation={item.interpretation}
                />
            )));
            setProofreadItem(categorizedItems.typos.map((item, index) => (
                <MyProofread
                    key={index}
                    correction={item.correction}
                    string_to_correct={item.string_to_correct}
                    error_desc_zh={item.error_desc_zh}
                    error_desc_sm_en={item.error_desc_sm_en}
                    group_type={item.group_type}
                    interpretation={item.interpretation}
                />
            )));
            setPunctuationItem(categorizedItems.punctuation_mistakes.map((item, index) => (
                <MyProofread
                    key={index}
                    correction={item.correction}
                    string_to_correct={item.string_to_correct}
                    error_desc_zh={item.error_desc_zh}
                    error_desc_sm_en={item.error_desc_sm_en}
                    group_type={item.group_type}
                />
            )));
            setProofreadTextItem(categorizedItems.typos.map((item, index) => (
                <MyProofread
                    key={index}
                    correction={item.correction}
                    string_to_correct={item.string_to_correct}
                    error_desc_zh={item.error_desc_zh}
                    error_desc_sm_en={item.error_desc_sm_en}
                    group_type={item.group_type}
                    interpretation={item.interpretation}
                />
            )));
        } catch (e) {
            openNotification('top', '出错了', e.message)
        }
    }

    //处理校对按钮
    const handleProofread = (type) => {
        if (type === 'typos') {
            setProofreadTextItem(proofreadItem)
        } else if (type === 'syntax') {
            setProofreadTextItem(grammarItem)
        } else if (type === 'punctuation') {
            setProofreadTextItem(punctuationItem)
        } else {
            console.log("默认情况")
            setProofreadTextItem(proofreadItem) // 默认情况下显示 proofreadItem
        }
    }

    //退出登录
    const login_out = () => {
        localStorage.removeItem('token')
        openNotification('top', '提示', '退出成功')
        //等待3秒
        setTimeout(function () {
            navigate('/')
        }, 3000);

    }

    return (
        <div className="cont">
            {contextHolder}
            <div className="editor" ref={ref1}>
                {editor && <MenuBar editor={editor}/>}
                <EditorContent onBlur={handleUpdate} onClick={loadLocalText} className="editor__content"
                               editor={editor}/>
                <div className="editor__footer">
                    <div>卡密有效期 <span>{kamiEndTime}</span></div>
                    <div>字数统计{text.length}</div>
                    {/*<Tour open={open} onClose={() => setOpen(false)} steps={steps}/>*/}
                </div>
            </div>
            <div></div>

            {/*<div className="right-cont" ref={ref3}>*/}
            {/*    <div className="my_logo"></div>*/}
            {/*    {loading ? <Skeleton active/> : myItem}*/}
            {/*</div>*/}
            <div ref={ref2}>
                <div className="right-side">
                    <div className='right-side-item' onClick={showStrong}>
                        <FormOutlined/>
                        移除AI痕迹
                    </div>
                    <div className='right-side-item' onClick={showAcademic}>
                        <FormOutlined/>
                        学术改写
                    </div>
                    <div className='right-side-item' onClick={changeText2}>
                        <FormOutlined/>
                        强力改写
                    </div>
                    <div className='right-side-item' onClick={showExpand}>
                       <DoubleRightOutlined/>
                       扩展
                    </div>
                    <div className='right-side-item' onClick={translateText}>
                        <SwapOutlined/>
                        翻译
                    </div>
                    <div className='right-side-item' onClick={showProofreadVisible}>
                        <CheckOutlined/>
                        校对
                    </div>

                    <div className='right-side-item' onClick={login_out}>
                        <CloseSquareOutlined/>
                        退出登录
                    </div>

                </div>


                <Drawer title="移除AI痕迹"
                        placement="right"
                        mask={false}
                        destroyOnClose={true}
                        onClose={onClose}
                        open={strongVisible}
                        className='drawer'
                        width='45%'
                        zIndex={1}
                >
                    <div>
                        <div className="top-btns">
                            <button className="change_text_btn" onClick={changeTestForSelectStrong}>移除选中AI痕迹
                            </button>
                            <button className="change_text_btn" onClick={changeText_Default}>移除全文AI痕迹</button>
                        </div>

                        {loading ? <Skeleton active/> : myItem}
                    </div>
                </Drawer>
                <Drawer title="学术改写"
                        placement="right"
                        mask={false}
                        destroyOnClose={true}
                        onClose={onClose}
                        open={academicVisible}
                        className='drawer'
                        width='45%'
                >
                    <div>
                        <div className="top-btns">
                            <button className="change_text_btn" onClick={academicRewrite}>学术改写选中文本</button>
                            <button className="change_text_btn" onClick={academicRewriteAll}>学术改写全部文本</button>
                        </div>

                        {loading ? <Skeleton active/> : myItem}
                    </div>
                </Drawer>

                <Drawer title="强力改写"
                        placement="right"
                        mask={false}
                        destroyOnClose={true}
                        onClose={onClose}
                        open={visible}
                        className='drawer'
                        width='45%'
                >
                    <div>
                        <div className="top-btns">
                            <button className="change_text_btn" onClick={changeText}>强力改写选中文本</button>
                            <button className="change_text_btn" onClick={changeTextAll}>强力改写全部文本</button>
                        </div>

                        {loading ? <Skeleton active/> : myItem}
                    </div>
                </Drawer>

                <Drawer title="扩展"
                        placement="right"
                        mask={false}
                        destroyOnClose={true}
                        onClose={onClose}
                        open={expandVisible}
                        className='drawer'
                        width='45%'
                >
                    <div>
                        <div className="top-btns">
                            <button className="change_text_btn" onClick={expandText}>扩写</button>
                            {/*<button className="change_text_btn" onClick={recommendText}>段落推荐</button>*/}
                        </div>

                        {loading ? <Skeleton active/> : myItem}
                    </div>
                </Drawer>

                <Drawer title="翻译"
                        placement="right"
                        mask={false}
                        onClose={onClose}
                        open={translateVisible}
                        destroyOnClose={true}
                        className='drawer'
                        width='45%'
                >
                    <div>
                        <div className="top-btns">
                            <button onClick={translateTextFu}>翻译</button>
                            <div className="change-lang-btn-box" onClick={
                                changeLanguage
                            }>
                                <span> &nbsp; {isChinese ? '中' : '英'} &nbsp;</span>
                                <SwapOutlined
                                    className={isChinese ? 'change-lang-btn-chinese' : 'change-lang-btn-english'}
                                    style={{fontSize: "1.3em", cursor: "pointer"}}/>
                                <span> &nbsp; {isChinese ? '英' : '中'}  &nbsp;</span>
                            </div>
                        </div>

                        {loading ? <Skeleton active/> : translateItem}
                    </div>
                </Drawer>

                <Drawer title="校对"
                        placement="right"
                        mask={false}
                        destroyOnClose={true}
                        onClose={onClose}
                        open={proofreadVisible}
                        className='drawer'
                        width='45%'
                >
                    <div>
                        <div className="top-btns">
                            <button className="change_text_btn" onClick={() => {
                                handleProofread("typos")
                            }}>
                                <span>{proofreadItem.length}</span>字词
                            </button>
                            <button className="change_text_btn" onClick={() => {
                                handleProofread("punctuation")
                            }}>{punctuationItem.length}标点
                            </button>
                            <button className="change_text_btn" onClick={() => {
                                handleProofread("syntax")
                            }}>{grammarItem.length}文法
                            </button>
                            <button onClick={proofreadText}>
                                <ReloadOutlined/>
                                &nbsp; 校对
                            </button>
                        </div>
                        {loading ? <Skeleton active/> : proofreadTextItem}
                        {/*{<MyProofread />}*/}
                    </div>
                </Drawer>
            </div>
        </div>
    )
}
