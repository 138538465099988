import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import {LoginPage} from "./login/LoginPage";

import {Home} from "./index/Home";
import {Hight} from "./menu/HomePage/Hight"; // 引入路由



const App = () => {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route exact path="/" element={<LoginPage/>}/>
                    {/*<Route exact path="/index" element={<Home/>}/>*/}
                    <Route exact path="/index" element={<Hight/>}/>
                </Routes>
            </div>
        </Router>
    );
};

export default App;
