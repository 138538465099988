import './style.scss'
import './myStyle.scss'
import CharacterCount from '@tiptap/extension-character-count'
import Highlight from '@tiptap/extension-highlight'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import {EditorContent, useEditor} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import React, {useEffect, useRef, useState} from 'react'
import MenuBar from './MenuBar'

import {Drawer, notification, Skeleton, Tour, TourProps} from "antd";
import type {NotificationPlacement} from "antd/es/notification/interface";
import {commFetchData} from "../util/util";
import {
    CloseSquareOutlined,
    FormOutlined, SwapOutlined,
} from "@ant-design/icons";
import {Placeholder} from "@tiptap/extension-placeholder";
import {useNavigate} from "react-router";
import MyProofread from "../proofreadComponent/MyProofread";
import {MyList} from "../contList/MyList";

export function Home() {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const [open, setOpen] = useState(false);
    const [changeData, setChangeData] = useState({})
    const [loading, setLoading] = useState(false);
    const [api, contextHolder] = notification.useNotification(); //弹窗
    const navigate = useNavigate();
    const [myItem, setMyItem] = useState(<h1
        className="right_msg">在左侧发挥您的构思吧，点击按钮后.我会帮助您建议您的文章</h1>) //列表
    const [visible, setVisible] = useState(false); //抽屉
    const [expandVisible, setExpandVisible] = useState(false); //扩展抽屉
    const [translateVisible, setTranslateVisible] = useState(false); //翻译抽屉
    const [strongVisible, setStrongVisible] = useState(false); //强力抽屉
    const [academicVisible, setAcademicVisible] = useState(false); //强力抽屉
    const [proofreadVisible, setProofreadVisible] = useState(false); //校对抽屉
    const [isChinese, setIsChinese] = useState(false); //是否中文
    const [kamiEndTime, setKamiEndTime] = useState(''); //卡密结束时间
    const [btnisDisabled, setBtnisDisabled] = useState(false); //按钮是否可用
    // 翻译文本
    const [translateItem, setTranslateItem] = useState(<p></p>); //翻译文本
    // 校对文本
    const [proofreadItem, setProofreadItem] = useState(<p/>); //校对文本
    const [punctuationItem, setPunctuationItem] = useState(<p/>); //标点文本
    const [grammarItem, setGrammarItem] = useState(<p/>); //语法文本


    //渲染校对文本
    const [proofreadTextItem, setProofreadTextItem] = useState(<p>点击校对按钮开始对您的文章进行校对吧</p>); //校对文本

    const steps: TourProps['steps'] = [
        {
            title: '编辑您的论文',
            description: '这里是编辑区，您可以尽情发挥您的创意',
            target: () => ref1.current,
            placement: 'rightBottom',
        },
        {
            title: '这里对您的文本进行改写',
            description: '我们提供了多种改写技术，将使用最强大的AI技术为您提供修改建议',
            target: () => ref2.current,
            placement: 'rightTop',
        },
        {
            title: 'welecome to use',
            description: '祝您使用愉快',
            target: () => ref3.current,
            placement: 'leftTop',
        },
    ];

    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                history: true,
            }),
            Highlight,
            TaskList,
            Placeholder,
            TaskItem,
            CharacterCount.configure({
                limit: 60000,
            }),
            Placeholder.configure({
                placeholder: '请输入您的文章',
            }),
        ],
    })
    // 定义text 为editor的内容 如果没有内容则为空
    const text = editor ? editor.getText() : ''
    const linesArray = text.split('\n').filter(function (line) {
        return line.trim() !== ''; // 过滤空行
    });
    //弹窗
    const openNotification = (placement: NotificationPlacement, title, msg) => {
        api.info({
            message: title,
            description: msg,
            placement,
        });
    };

    //页面加载调用
    useEffect(() => {
        init()
    }, [])


    //获取选中的内容
    const getSelection = () => {
        if (editor) {
            const {from, to} = editor.state.selection;
            const text = editor.getText();
            const selectedText = text.slice(from - 1, to - 1);
            if (selectedText === '\n' && from === to - 1) {
                return ''; // 如果选中的是空行，则返回空字符串
            }
            return selectedText.replace(/\r?\n/g, '');
        }

        return '';
    };

    //设置选中的内容
    const setSelection = (value) => {
        if (editor) {
            const {from, to} = editor.state.selection
            editor.commands.insertContentAt({from: from, to: to}, value)
        }
    }

    //加载本地文本
    function loadLocalText() {
        let localText = localStorage.getItem('text')
        if (localText === null) {
            localText = ''
        }
        // 获取当前文本内容
        let cont = editor.getText()
        if (cont.length === 0) {
            editor.commands.setContent(localText.toString())
        }
    }

    const translateText = () => {
        showTranslate()
    }
    const showTranslate = () => {
        if (translateVisible) {
            setTranslateVisible(false)
        } else {
            setTranslateVisible(true)
            setExpandVisible(false)
            setVisible(false)
            setStrongVisible(false)
            setAcademicVisible(false)
            setProofreadVisible(false)
        }
    };
    const handleUpdate = () => {
        const newContent = editor.getHTML()
        localStorage.removeItem('text')
        localStorage.setItem('text', newContent.toString());
    };

    // 改写选中文本
    const changeText = async (type, mode) => {
        try {
            debounce()
            if (btnisDisabled) {
                openNotification('top', '出错了', '点击太快了，稍后再试吧')
                return
            }
            let allText = editor.getText()
            let linesArray = allText.split('\n').filter(function (line) {
                return line.trim() !== ''; // 过滤空行
            });

            setMyItem(<div></div>)
            setChangeData({})
            setLoading(true); // 显示骨架屏

            if (linesArray.length === 0) {
                openNotification('top', '抱歉', '请输入文本')
                return
            }
            //全文改写
            let cont = []
            // 遍历 linesArray

            //段落
            let data = {
                'content': allText,
                'isSampleDoc': false,
                'mode': mode,
                'needClear': false,
                'type': type,
                "token": localStorage.getItem('token')
            }
            let rep = await commFetchData('html/bilingualText', data);
            cont = JSON.parse(rep.bilingualResults)
            console.log(cont)
            setMyItem(cont.map((item, index) => {
                    if (item === '') {
                        return
                    }

                    return (
                        <MyList cont={item} key={index}/>
                    )
                })
            )

            if (mode === 2) {
                setTranslateItem(cont.map((item, index) => {
                        if (item === '') {
                            return
                        }

                        return (
                            <p key={index} className="translate_item">{item}</p>
                        )
                    })
                )
            }
            setLoading(false)

        } catch (e) {
            openNotification('top', '出错了', e.message)
            setLoading(false)
        }
    }


    //初始化页面调用
    function init() {
        //判断用户是否第一次登录
        let isFirst = localStorage.getItem('isFirstLogin')
        let endTime = localStorage.getItem('endTime')
        if (endTime) {
            setKamiEndTime(endTime)
        }
        if (isFirst === 'true') {
            setOpen(true)
            // 设置本地存储标记为已登录
            localStorage.setItem('isFirstLogin', false);
        }

        let isLogin = localStorage.getItem('token')
        if (!isLogin) {
            alert("请先登录")
            navigate('/');
            //删除所有 dom
            setOpen(false)
        }
    }

    function doSomething(value) {
        // 判断value是否为数组
        if (Array.isArray(value)) {
            // 如果是数组，就把数组转换成字符串
            // 将value 使用 p标签包裹起来，然后转换成字符串，去除逗号，去除第一个p标签
            value = value.map(item => `<p>${item}</p>`).join('').replace(/,/g, '').replace('<p>', '').replace('</p>', '')
        }

        setSelection(value)
        //使滚动条在光标处
        editor.commands.scrollIntoView()
    }


    //显示强力改写
    const showStrong = () => {
        setMyItem(<h1
            className="right_msg">在左侧发挥您的构思吧，点击按钮后.我会帮助您建议您的文章</h1>)
        if (strongVisible) {
            setStrongVisible(false)
        } else {
            setTranslateVisible(false)
            setExpandVisible(false)
            setVisible(false)
            setStrongVisible(true)
            setAcademicVisible(false)
            setProofreadVisible(false)
        }
    };
    //显示校对
    const showProofreadVisible = () => {
        setMyItem(<h1
            className="right_msg">在左侧发挥您的构思吧，点击按钮后.我会帮助您建议您的文章</h1>)
        if (proofreadVisible) {
            setProofreadVisible(false)
        } else {
            setTranslateVisible(false)
            setExpandVisible(false)
            setVisible(false)
            setStrongVisible(false)
            setAcademicVisible(false)
            setProofreadVisible(true)
        }
    };

    const onClose = () => {
        // setVisible(false);
        if (expandVisible) {
            setExpandVisible(false)
        } else if (translateVisible) {
            setTranslateVisible(false)
        } else if (visible) {
            setVisible(false)
        } else if (academicVisible) {
            setAcademicVisible(false)
        } else if (strongVisible) {
            setStrongVisible(false)
        } else if (proofreadVisible) {
            setProofreadVisible(false)
        }
    };

    //按钮防抖
    const debounce = () => {
        setBtnisDisabled(true)
        setTimeout(() => {
            setBtnisDisabled(false)
        }, 1000)
    }
    //段落推荐


    const handleProofread = (type) => {
        if (type === 'typos') {
            setProofreadTextItem(proofreadItem)
        } else if (type === 'syntax') {
            setProofreadTextItem(grammarItem)
        } else if (type === 'punctuation') {
            setProofreadTextItem(punctuationItem)
        } else {
            console.log("默认情况")
            setProofreadTextItem(proofreadItem) // 默认情况下显示 proofreadItem
        }
    }
    //退出登录
    const login_out = () => {
        localStorage.removeItem('token')
        openNotification('top', '提示', '退出成功')
        //等待3秒
        setTimeout(function () {
            navigate('/')
        }, 3000);

    }

    return (
        <div className="cont">
            {contextHolder}
            <div className="editor" ref={ref1}>
                {editor && <MenuBar editor={editor}/>}
                <EditorContent onBlur={handleUpdate} onClick={loadLocalText} className="editor__content"
                               editor={editor}/>
                <div className="editor__footer">
                    <div>卡密有效期 <span>{kamiEndTime}</span></div>
                    <div>字数统计{text.length}</div>
                    <Tour open={open} onClose={() => setOpen(false)} steps={steps}/>
                </div>
            </div>
            <div></div>

            {/*<div className="right-cont" ref={ref3}>*/}
            {/*    <div className="my_logo"></div>*/}
            {/*    {loading ? <Skeleton active/> : myItem}*/}
            {/*</div>*/}
            <div ref={ref2}>
                <div className="right-side">
                    <div className='right-side-item' onClick={showStrong}>
                        <FormOutlined/>
                        改写
                    </div>
                    <div className='right-side-item' onClick={translateText}>
                        <SwapOutlined/>
                        翻译
                    </div>

                    <div className='right-side-item' onClick={login_out}>
                        <CloseSquareOutlined/>
                        退出登录
                    </div>
                </div>
                <Drawer title="改写"
                        placement="right"
                        mask={false}
                        destroyOnClose={true}
                        onClose={onClose}
                        open={strongVisible}
                        className='drawer'
                        width='45%'
                        zIndex={1}
                >
                    <div>
                        <div className="top-btns">
                            <button className="change_text_btn" onClick={() => {
                                changeText(1, 1)
                            }}>普通改写
                            </button>
                            <button className="change_text_btn" onClick={() => {
                                changeText(2, 1)
                            }}>强力改写
                            </button>
                            <button className="change_text_btn" onClick={() => {
                                changeText(3, 1)
                            }}>学术改写
                            </button>
                        </div>

                        {loading ? <Skeleton active/> : myItem}
                    </div>
                </Drawer>

                <Drawer title="翻译"
                        placement="right"
                        mask={false}
                        onClose={onClose}
                        open={translateVisible}
                        destroyOnClose={true}
                        className='drawer'
                        width='45%'
                >
                    <div>
                        <div className="top-btns">
                            <button onClick={() => {
                                changeText(1, 2)
                            }}>翻译
                            </button>
                            {/*<div className="change-lang-btn-box" onClick={()=>{changeText(1,2)}}>*/}
                            {/*    <span> &nbsp; 中 &nbsp;</span>*/}
                            {/*    <SwapOutlined*/}
                            {/*        className={isChinese ? 'change-lang-btn-chinese' : 'change-lang-btn-english'}*/}
                            {/*        style={{fontSize: "1.3em", cursor: "pointer"}}/>*/}
                            {/*    <span> &nbsp; 英  &nbsp;</span>*/}
                            {/*</div>*/}
                        </div>

                        {loading ? <Skeleton active/> : translateItem}
                    </div>
                </Drawer>
            </div>
        </div>
    )
}
