import {commFetchData} from '../util/util';
//翻译
const fetchTranslate = async (data) => {
    return await commFetchData('/translate', data)
}
//改写
const fetchChangeText = async (data,type) => {
    if (type === 'select') {
        return await commFetchData('/select_strong_v1', data)
    }else if (type === 'all') {
        return await commFetchData('/strong_v1', data)
    }
    throw new Error('type is not defined')
}
//校对
//退出登录
//登录
const fetchLogin = async (data) => {
    return await commFetchData('/login', data)
}

export {
    fetchTranslate,
    fetchLogin
}